import React, { useState }  from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import axios from "axios"

const Contact = ({ data, location }) => {


    const siteTitle = data.site.siteMetadata?.title || `Title`
    const [AvailablePortResult, setAvailablePortResult] = useState()
    const [manualAvailablePortResult, setmanualAvailablePortResult] = useState()
    const [IpAddress, setIpAddress] = useState()
    const [isIPv4, setisIPv4] = useState(false)
    const [buttonDisabled, setbuttonDisabled] = useState(false)
    const [ButtonClicked, setButtonClicked] = useState(false)
    const [manualButtonClicked, setmanualButtonClicked] = useState(false)

    const getIpAddress = `https://us-central1-isec-gatsby-staging.cloudfunctions.net/getIpAddress`

    let PortResult
    if(ButtonClicked) {
      PortResult = (
        <div>
          <br/>
          <p><b>IPアドレス：</b>{IpAddress}</p>         
            {isIPv4 ? 
            <p>{AvailablePortResult}</p> 
            : <> 
            <p><b>使用可能ポート</b></p>
            <p>{AvailablePortResult}</p>
            </>
            }
        </div>
      )
    } else {
      PortResult = (
        <div></div>
      )
    }

    let manualPortResult
    if(manualButtonClicked) {
      manualPortResult = (
        <div>
          <br/>
          <p><b>使用可能ポート</b></p>
          <p>{manualAvailablePortResult}</p>
        </div>
      )
    } else {
      manualPortResult = (
        <div></div>
      )
    }

    const GetAvailablePort = (e) => {
      e.preventDefault()
      var GetIpAdress01
      setIpAddress("確認中…")
      setAvailablePortResult("確認中…")
      axios.get(getIpAddress)
      .then(response => {
        // console.log(response)
        setIpAddress(response.data)
        GetIpAdress01 = response.data
        // GetIpAdress01 = "ABCD:EF01:2345:7189:ABCD:EF01:2345:6789"
        // setIpAddress(GetIpAdress01)
        // GetIpAdress = "192.168.0.100"
        
        // setIpAddress(GetIpAdress)
        if(GetIpAdress01.indexOf(".") !== -1){
          setisIPv4(true)
          setAvailablePortResult("IPv4アドレスを使用しています。IPv6で接続してください。")
        } else {
          let fourthField = GetIpAdress01.split(':')
          if( fourthField[3].length === 3) {
            fourthField[3] = 0 + fourthField[3]
          } else if (fourthField[3].length < 3) {
            fourthField[3] = "0000"
          }
          let string = fourthField[3].substr(0,2) + 0
          let result = ""
          for (let index = 1; index < 16; index++) {
            result += index * 16 * 16 * 16 + parseInt(string, 16)
            result += "-"
            result += index * 16 * 16 * 16 + parseInt(string, 16) + 15
            if(index !== 15) result += "、" 
          }
          setAvailablePortResult(result)
        }
      })
      .catch(error => console.log(error))
      
      // 送信ボタン連打防止用に1秒間のクールタイム
      setbuttonDisabled(true)
      setTimeout(() => setbuttonDisabled(false) , 1000)
      setButtonClicked(true)
    }

    //手動で使用可能ポートを調べる。送信ボタン押下時の処理
    const manualGetAvailablePort = (e) => {
      e.preventDefault()
      let string = e.target.field.value
      if(string.length === 3){
        string = "0" + e.target.field.value
      } else if(string.length < 3) {
        string = "0000"
      }
      string = string.substr(0,2) + 0
      let result = ""
      for (let index = 1; index < 16; index++) {
        result += index * 16 * 16 * 16 + parseInt(string, 16)
        result += "-"
        result += index * 16 * 16 * 16 + parseInt(string, 16) + 15
        if(index !== 15) result += "、" 
      }
      setmanualAvailablePortResult(result)
      // 送信ボタン連打防止用に1秒間のクールタイム
      setbuttonDisabled(true)
      setTimeout(() => setbuttonDisabled(false) , 1000)
      setmanualButtonClicked(true)
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="IPv6（MAP-E方式）使用可能ポート確認" />
        <div className="post-header">
            <h1>IPv6(MAP-E方式)使用可能ポート確認</h1>
        </div>
        <div className="post-body">
            <p>NTT東西のフレッツ網で使用されるMAP-E方式のIPv4 over IPv6接続サービス （v6プラス、v6アルファ、IPv6オプション、v6エクスプレス等）の使用可能ポート確認にお使いください</p>

            <h4>IPv6の使用可能ポートを調べる</h4>
            <form onSubmit={GetAvailablePort}>
            <input type="submit" value="送信" className="submit_button" disabled={buttonDisabled}/>
            </form>
            {PortResult}
            <h4>手動で使用可能ポートを調べる</h4>
            <form onSubmit={manualGetAvailablePort}>
            <label for="field">IPv6アドレス第4フィールドの<b>アドレス(0000からFFFFまで、半角英数字)</b>を入力してください。</label>
            <input type="text" name="field" pattern="^[0-9A-Fa-f]+$" maxlength="4" className="small_form" required/>
            <span>&nbsp;&nbsp;</span>
            <input type="submit" value="送信" className="submit_button" disabled={buttonDisabled}/>
            <p>例：IPv6アドレスが&nbsp;ABCD:EF01:2345:<b>6789</b>:ABCD:EF01:2345:6789&nbsp;の場合</p>
            <p>第4フィールドの<b>6789</b>を入力します。</p>
            <p>※第4フィールドが省略表記の場合は上位桁に0を追加します。例：12なら<b>0012</b>を入力。</p>
            </form>
            {manualPortResult}
            <h4>&nbsp;</h4>
            <p><Link to={`/tools`} >ツール一覧に戻る</Link></p>
        </div>
        </Layout>
    )
}

export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
